<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-vertical detail-width">
      <article class="tile is-child box detail-page-tile">
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">Order Receipt Detail
              <span v-show="!innerValue.isNew"
                class="icon has-text-info tooltip is-tooltip-info"
                :class="[ canChangeReadOnly ? 'readonly-pointer' : '' ]"
                :data-tooltip="readOnlyView ? 'Invoice is locked' : 'Invoice is unlocked'"
                @click="toogleReadOnly()">
                <i class="icon mdi"
                  :class="[ readOnlyView ? 'mdi-lock-outline' : 'mdi-lock-open-outline' ]" />
              </span>
            </p>
          </div>
          <div class="column">
            <div class="control is-pulled-right">
              <button class="button tooltip is-tooltip-topright ml-2"
                @click="backToListing"
                data-tooltip="Back to order receipt listing">
                <span class="icon has-text-primary">
                  <i class="mdi mdi-chevron-double-left mdi-18px" />
                </span>
              </button>
            </div>
            <div v-if="isLockPurchaseOrder">
              <order-lock-notification />
            </div>
          </div>
        </div>
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          v-if="activeItems"
          :columns="columns"
          :page-index="1"
          :page-size="100"
          :total-rows="activeItems.length"
          :show-pagination="false"
          :is-loading="isTableLoading"
          :sort-column="sortColumn"
          :sort-order="sortOrder"
          @sort="sort">
          <tr v-for="(item, index) in activeItems"
            :key="item.invoiceId"
            :class="{ 'is-selected' : selectedRow === index }">
            <td class="has-vertical-middle">{{ item.itemNo }}</td>
            <td class="has-vertical-middle">{{ item.description }}</td>
            <td class="has-vertical-middle has-text-centered">{{ item.side }}</td>
            <td class="has-vertical-middle has-text-right">{{ item.owning }}</td>
            <td class="has-vertical-middle partno-input">
              <div v-if="(item.itemType === 'PART' || item.itemType === 'OTHER') && !readOnlyView">
                <input class="input"
                  placeholder="Part No"
                  :disabled="isLockPurchaseOrder"
                  v-model="item.partNo">
              </div>
              <div v-else>{{ item.partNo }}</div>
            </td>
            <td class="has-vertical-middle has-text-right money-input">
              <vue-numeric v-if="!readOnlyView"
                class="input has-text-right"
                v-model.number="item.unitCost"
                :disabled="isLockPurchaseOrder"
                :min="0"
                :precision="2" />
              <span v-else>{{ item.unitCost | formatNumber($userInfo.locale) }}</span>
            </td>
            <td class="has-vertical-middle has-text-right quantity-input">
              <vue-numeric v-if="!readOnlyView"
                class="input has-text-right"
                :min="0"
                :disabled="isLockPurchaseOrder"
                :precision="0"
                v-model.number="item.received"
                @input="checkRecQty(item)" />
              <span v-else>{{ item.received }}</span>
            </td>
            <td class="has-vertical-middle has-text-right money-input">
              <vue-numeric v-if="!readOnlyView"
                class="input has-text-right"
                v-model.number="item.dealerCost"
                :min="0"
                :disabled="isLockPurchaseOrder"
                :precision="2"
                @input.native="updateBeforeGst(item)" />
              <span v-else>{{ item.dealerCost | formatNumber($userInfo.locale) }}</span>
            </td>
            <td class="has-vertical-middle has-text-right money-input">
              <vue-numeric v-if="!readOnlyView"
                class="input has-text-right"
                v-model.number="item.totalAmount"
                :min="0"
                :disabled="isLockPurchaseOrder"
                :precision="2"
                @input.native="updateAfterGst(item)" />
              <span v-else>{{ item.totalAmount | formatNumber($userInfo.locale) }}</span>
            </td>
            <td class="has-vertical-middle has-text-right gst-input">
              <vue-numeric v-if="!readOnlyView"
                class="input has-text-right"
                v-model.number="item.gstAmount"
                :min="0"
                :disabled="isLockPurchaseOrder"
                :precision="2"
                @input.native="checkGst(item)" />
              <span v-else>{{ item.gstAmount | formatNumber($userInfo.locale) }}</span>
            </td>
            <td class="has-vertical-middle account-input">
              <div v-if="!readOnlyView">
                <multiselect v-if="glAccountListCombo && selectedItemAccounts.length > 0"
                  v-model="selectedItemAccounts[index]"
                  :options="glAccountListCombo"
                  placeholder="Select account"
                  label="displayName"
                  track-by="accountNo"
                  deselect-label="Can't remove this value"
                  :show-labels="false"
                  :allow-empty="false">
                  <span class="has-text-danger"
                    slot="noResult">G/L account not found.</span>
                </multiselect>
              </div>
              <div v-else>
                <multiselect v-if="glAccountListCombo && selectedItemAccounts.length > 0"
                  v-model="selectedItemAccounts[index]"
                  :options="glAccountListCombo"
                  placeholder="Select account"
                  label="displayName"
                  track-by="accountNo"
                  deselect-label="Can't remove this value"
                  :show-labels="false"
                  :allow-empty="false"
                  :disabled="true" />
              </div>
            </td>
          </tr>
          <template slot="footer">
            <tr>
              <th class="has-text-right has-text-success"
                :colspan="8">
                <span v-if="totalReceiptQty === 0"
                  class="icon has-text-danger tooltip is-tooltip-danger"
                  data-tooltip="You have not received any items.">
                  <i class="mdi mdi-alert mdi-18px" />
                </span>
                <span v-else-if="receiptEntity.totalAmount === 0 && receiptEntity.overrideIncGst === 0"
                  class="icon has-text-danger tooltip is-tooltip-danger is-tooltip-multiline"
                  data-tooltip="Amount for received item or an Override Amount cannot be zero!">
                  <i class="mdi mdi-alert mdi-18px" />
                </span>
                Totals
              </th>
              <th class="has-text-right has-text-success"
                :class="'has-text-success'">{{ totalAfterGst | formatCurrency($userInfo.locale) }}
              </th>
              <th class="has-text-right has-text-success"
                :class="'has-text-success'">{{ totalGst | formatCurrency($userInfo.locale) }}</th>
              <th colspan="1" />
            </tr>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>receiptEntity
              </div>
            </section>
          </template>
        </bulma-table>
        <div class="is-divider" />
        <div class="columns is-gapless">
          <div class="column">
            <p class="title">
              Attachments
            </p>
          </div>
          <div class="column">
            <!-- cant apply disabled to file picker button -->
            <form ref="uploader"
              v-if="!readOnlyView && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
              enctype="multipart/form-data"
              novalidate>
              <div class="field">
                <div class="file is-pulled-right is-primary">
                  <label class="file-label">
                    <!-- <input class="file-input" type="file" name="resume"> -->
                    <input type="file"
                      class="file-input"
                      :name="uploadFieldName"
                      :disabled="isSaving || readOnlyView"
                      @change="filesChange($event.target.name, $event.target.files);fileCount = $event.target.files.length"
                      accept="image/png, image/jpeg, .pdf">
                    <span class="file-cta">
                      <span class="file-icon">
                        <i class="mdi mdi-upload mdi-20px" />
                      </span>
                      <span class="file-label">
                        Select files...
                      </span>
                    </span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="attachmentsColumns"
          :page-index="1"
          :page-size="100"
          :total-rows="attachments.length"
          :show-pagination="false"
          :is-loading="isTableLoading"
          :sort-column="sortColumn"
          :sort-order="sortOrder"
          @sort="sort">
          <tr v-for="(item, index) in attachments"
            :key="item.id"
            :class="{ 'is-selected' : selectedRow === index }">
            <td>{{ $filters.formatDateTimeLocale(item.createdDate, $userInfo.locale) }}</td>
            <td class="has-vertical-middle">
              <a href="#"
                @click="downloadAttachment(item)">{{ item.fileName }}</a>
            </td>
            <td>{{ item.fileSize }}</td>
            <td class="has-vertical-middle has-text-centered is-content-width">
              <button class="button is-danger is-small is-inverted"
                @click="deleteAttachment(item)"
                :disabled="readOnlyView || receiptEntity.receiptItems.length === 0">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </button>
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
        </bulma-table>
      </article>
    </div>
    <div class="tile is-parent is-vertical is-2">
      <article class="tile is-child box detail-page-tile">
        <div class="field is-narrow">
          <label class="label">Invoice Number</label>
          <div v-if="!readOnlyView && ((receiptEntity.isNew && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0) ||
                 ($userInfo.isSupportUser && !receiptEntity.exported))"
            class="control is-narrow">
            <input class="input"
              :class="{ 'is-danger' : !receiptEntity.isValid && invoiceError !== null }"
              v-model="receiptEntity.invoiceNo"
              type="text"
              placeholder=""
              @input="checkInvoiceNoExists(receiptEntity)">
            <span v-if="!receiptEntity.isValid && invoiceError !== null"
              class="help is-danger">{{ invoiceError }}</span>
          </div>
          <div v-else>
            <span>{{ receiptEntity.invoiceNo }} </span>
          </div>
        </div>
        <div class="field is-narrow">
          <label class="label">Date</label>
          <v-date-picker v-if="!readOnlyView && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
            v-model="selectedInvoiceDate"
            :masks="formats"
            :attributes="attrs"
            :locale="$userInfo.locale"
            :min-date="firstDayOfMonth"
            @input="selectedInvoiceDate = $filters.fromISOWithCurrentTime($event.toISOString())"
            :timezone="$filters.getIanaTimezone()">
            <template v-slot="{ inputValue, inputEvents }">
              <div class="field">
                <div class="control has-icons-left">
                  <input type="text"
                    class="input"
                    placeholder="Invoice Date"
                    :value="inputValue"
                    v-on="inputEvents"
                    readonly>
                  <span class="icon is-small is-left">
                    <i class="mdi mdi-calendar mdi-18px" />
                  </span>
                </div>
              </div>
            </template>
          </v-date-picker>
          <span v-else>
            <input class="input is-static"
              :value="$filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
              readonly>
          </span>
        </div>
        <div class="field is-narrow">
          <label class="label">ABN</label>
          <div v-if="!readOnlyView && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
            class="control has-icons-right">
            <the-mask v-if="$company.info.countryCode === 'AU'"
              id="abn"
              :class="{ 'is-danger' : receiptEntity.abn && $v.entity.orderReceipts.$each[idx].abn.$error}"
              name="abn"
              ref="abn"
              class="input abn"
              v-model="receiptEntity.abn"
              placeholder="ABN"
              :mask="['## ### ### ###']" />
            <input v-else
              v-model="receiptEntity.abn"
              id="abn"
              ref="abn"
              class="input is-uppercase"
              maxlength="12">
            <span v-if="receiptEntity.abn && !$v.entity.orderReceipts.$error && $v.entity.orderReceipts.$each[idx].abn.validAbn && $v.entity.orderReceipts.$each[idx].abn.minLength && $company.info.countryCode === 'AU'"
              class="icon has-text-success is-right">
              <i class="mdi mdi-check mdi-18px" />
            </span>
            <span class="help is-danger"
              v-if="receiptEntity.abn && $v.entity && !$v.entity.orderReceipts.$each[idx].abn.minLength">ABN must be {{ $v.entity.orderReceipts.$each[idx].abn.$params.minLength.min }} digits.</span>
            <span class="help is-danger"
              v-else-if="receiptEntity.abn && $v.entity && !$v.entity.orderReceipts.$each[idx].abn.validAbn">ABN format is invalid.</span>
          </div>
          <div v-else>
            <span>{{ receiptEntity.abn }}</span>
          </div>
        </div>
        <div class="field is-narrow">
          <label class="label">Total Amt. (Incl GST)</label>
          <div class="control">
            <vue-numeric v-if="!readOnlyView && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
              class="input has-text-right"
              v-model="receiptEntity.overrideIncGst"
              type="text"
              placeholder=""
              :precision="2"
              @input.native="updateOverrideTotal(receiptEntity)" />
            <span v-else>{{ receiptEntity.overrideIncGst | formatCurrency($userInfo.locale) }}</span>
          </div>
        </div>
        <div class="field is-narrow">
          <label class="label">GST</label>
          <div class="control">
            <vue-numeric v-if="!readOnlyView && receiptEntity.receiptItems && receiptEntity.receiptItems.length > 0"
              class="input has-text-right"
              v-model="receiptEntity.overrideGst"
              type="text"
              placeholder=""
              :precision="2"
              @input.native="checkOverrideGst(receiptEntity)" />
            <span v-else>{{ receiptEntity.overrideGst | formatCurrency($userInfo.locale) }}</span>
          </div>
        </div>

        <div v-if="receiptEntity.overrideIncGst != totalAfterGst"
          class="field is-narrow">
          <span class="has-text-danger">Rounding Inv. Amt. {{ receiptEntity.overrideIncGst - totalAfterGst| formatCurrency($userInfo.locale) }}</span>
        </div>

        <div class="field is-narrow">
          <label class="label">Exported</label>
          <div class="pretty p-icon p-curve p-smooth p-bigger">
            <input id="chk-invoice-exported"
              name="chk-invoice-exported"
              v-model="receiptEntity.exported"
              type="checkbox"
              :disabled="readOnlyView || !$userInfo.isSupportUser">
            <div class="state p-primary">
              <i class="icon mdi mdi-check" />
              <label>Exported</label>
            </div>
          </div>
        </div>

      </article>
    </div>
    <confirm-modal v-if="currentAttachment"
      :active.sync="isConfirmModalActive"
      @ok="confirmDelete(true)"
      @cancel="confirmDelete(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Attachment</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ currentAttachment.fileName }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _orderBy from 'lodash/orderBy'
import Multiselect from 'vue-multiselect'
import BulmaTable from '@/components/BulmaTable'
import VueNumeric from '@/components/VueNumeric'
import UploadService from '@/components/FileUploadService'
import { EventHubTypes, InvoicePrefixShortFormTypes } from '@/enums'
import Guid from '@/components/Guid'
import StoreMixin from './storeMixin'
import PurchaseOrderService from './PurchaseOrderService'
import { NumberFiltersMixin, DateTimeFiltersMixin } from '@/components/mixins/filters'
import { OrderReceiptDetailColumns, AttachmentsColumns } from './columns'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { ConfirmModal } from '@/components/BulmaModal'
import QuoteService from '@/views/quote/QuoteService.js'
// import { PurchaseOrderReceiptModel, PurchaseOrderReceiptItemModel } from '@/classes/viewmodels'
import OrderLockNotification from './components/PurchaseOrderLockNotification'

const STATUS_INITIAL = 0
const STATUS_SAVING = 1
const STATUS_SUCCESS = 2
const STATUS_FAILED = 3
const title = 'Order Receipt'

export default {
  name: 'PurchaseOrderOrderReceiptDetail',
  inject: ['$vv'],
  components: {
    BulmaTable,
    VueNumeric,
    ConfirmModal,
    Multiselect,
    OrderLockNotification
  },
  filters: {},
  mixins: [StoreMixin, NumberFiltersMixin, DateTimeFiltersMixin],
  props: {
    value: null
  },
  data() {
    return {
      quoteAttachmentCategories: [],
      innerValue: _cloneDeep(this.value),
      glAccountListCombo: [],
      selectedItemAccounts: [],
      currentStatus: null,
      uploadFieldName: '',
      isTableLoading: false,
      isGlBankAccountLoading: false,
      selectedRow: false,
      selectedInvoiceDate: null,
      invoiceError: null,
      isValidInvoice: false,
      isValidQtyAmt: false,
      idx: 0,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      isLoadAttachmentsActive: false,
      attachments: [],
      isConfirmModalActive: false,
      currentAttachment: null,
      sortColumn: '',
      sortOrder: ''
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    columns() {
      return OrderReceiptDetailColumns
    },
    attachmentsColumns() {
      return AttachmentsColumns
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED
    },
    receiptEntity() {
      return this.innerValue.orderReceipts.filter((i) => i.creditorInvoiceId === this.$route.params.creditorInvoiceId)[0] ?? []
    },
    activeItems() {
      return this.receiptEntity.receiptItems
    },
    totalBeforeGst() {
      if (!this.activeItems) {
        return 0
      } else {
        return roundAwayFromZero(
          this.activeItems
            .filter((i) => i.received !== 0)
            .reduce(function (total, item) {
              return total + item.dealerCost
            }, 0),
          2
        )
      }
    },
    totalAfterGst() {
      if (!this.activeItems) {
        return 0
      } else {
        return roundAwayFromZero(
          this.activeItems
            .filter((i) => i.received !== 0)
            .reduce(function (total, item) {
              return total + item.totalAmount
            }, 0),
          2
        )
      }
    },
    totalGst() {
      if (!this.activeItems) {
        return 0
      } else {
        return roundAwayFromZero(
          this.activeItems
            .filter((i) => i.received !== 0)
            .reduce(function (total, item) {
              return total + item.gstAmount
            }, 0),
          2
        )
      }
    },
    totalReceiptQty() {
      if (!this.activeItems) {
        return 0
      } else {
        return roundAwayFromZero(
          this.activeItems
            .filter((i) => i.received !== 0)
            .reduce(function (total, item) {
              return total + item.received
            }, 0),
          2
        )
      }
    },
    poItems() {
      return this.innerValue.parts.concat(this.innerValue.miscs, this.innerValue.sublets, this.innerValue.otherPurchases)
    },
    firstDayOfMonth() {
      if (this.$company.setting.allowInvoicePreviousMonth) {
        return null
      }
      let invoiceDate = new Date()
      if (!this.innerValue.isNew) {
        invoiceDate = new Date(`${this.selectedInvoiceDate}`)
        return this.$filters.firstDayOfMonth(invoiceDate.getFullYear(), invoiceDate.getMonth())
      } else {
        return this.$filters.firstDayOfMonth()
      }
    },
    overrideTotalAfterGst() {
      if (!this.activeItems) {
        return 0
      } else {
        return roundAwayFromZero(
          this.activeItems
            .filter((i) => i.received !== 0)
            .reduce(function (total, item) {
              return total + item.totalAmount
            }, 0),
          2
        )
      }
    },
    overrideTotalBeforeGst() {
      if (!this.activeItems) {
        return 0
      } else {
        return roundAwayFromZero(
          this.activeItems
            .filter((i) => i.received !== 0)
            .reduce(function (total, item) {
              return total + item.dealerCost
            }, 0),
          2
        )
      }
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedInvoiceDate(newVal, oldVal) {
      let found = this.innerValue.orderReceipts.some((i) => i.creditorInvoiceId === this.$route.params.creditorInvoiceId)
      if (found) {
        if (newVal) {
          this.innerValue.orderReceipts.filter((i) => i.creditorInvoiceId === this.$route.params.creditorInvoiceId)[0].invoiceDate =
            newVal.toISOString().split('.')[0] + 'Z'
        }
      }
    },
    selectedItemAccounts: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal.length > 0) {
          const vm = this
          newVal.forEach(function (account, index) {
            if (account) {
              vm.receiptEntity.receiptItems[index].account = account.accountNo
            }
          })
        }
      }
    },
    isValidInvoice(newVal, oldVal) {
      if (newVal && this.isValidQtyAmt) {
        this.receiptEntity.isValid = true
      } else {
        this.receiptEntity.isValid = false
      }
    },
    isValidQtyAmt(newVal, oldVal) {
      if (newVal && this.isValidInvoice) {
        this.receiptEntity.isValid = true
      } else {
        this.receiptEntity.isValid = false
      }
    },
    totalBeforeGst(newVal, oldVal) {
      if (newVal) {
        this.receiptEntity.amount = newVal
      }
    },
    totalGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.gstAmount = newVal
        this.receiptEntity.overrideGst = newVal
      }
    },
    totalAfterGst(newVal, oldVal) {
      if (newVal) {
        this.receiptEntity.totalAmount = newVal
      }
    },
    overrideTotalAfterGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.overrideIncGst = newVal
      }
    },
    overrideTotalBeforeGst(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.receiptEntity.overrideExGst = newVal
      }
    }
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
    this.$eventHub.$off(EventHubTypes.EntityReload)
  },
  async created() {
    this.quoteAttachmentCategories = await QuoteService.getQuoteAttachmentCategories()
    this.$v.entity.orderReceipts.$touch()
    let found = this.innerValue.orderReceipts.some((i) => i.creditorInvoiceId === this.$route.params.creditorInvoiceId) // for refresh F5
    if (found) {
      this.idx = this.innerValue.orderReceipts
        .map(function (id) {
          return id.creditorInvoiceId
        })
        .indexOf(this.$route.params.creditorInvoiceId)
      // this.receiptEntity = this.innerValue.orderReceipts.filter(i => i.creditorInvoiceId === this.$route.params.creditorInvoiceId)[0]
      this.selectedInvoiceDate = new Date(`${this.receiptEntity.invoiceDate}`)

      if (!this.receiptEntity.isValid) {
        this.checkInvoiceNoExists(this.receiptEntity)
        this.checkQtyAmount(this.receiptEntity)
      } else {
        this.isValidInvoice = true
        this.isValidQtyAmt = true
      }
      if (this.receiptEntity.exported)
      {
        this.setReadOnlyView(true)
      }
      else
      {
        this.setReadOnlyView(false)
      }
      await this.GetGlAccountsDropdown()
      this.generateSelectedAccounts()
    } else {
      //this.addNewOrderReceipt(this.$route.params.creditorInvoiceId)
      this.$router.push({
        name: 'PurchaseOrderOrderReceipt',
        params: { purchaseOrderId: this.innerValue.purchaseOrderId }
      })
    }
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })

    this.$eventHub.$on(EventHubTypes.EntityReload, () => {
      this.innerValue = _cloneDeep(this.value)
    })

    this.getAttachments()
  },
  methods: {
    async GetGlAccountsDropdown() {
      this.isGlBankAccountLoading = true
      this.glAccountListCombo = await PurchaseOrderService.getGlAccountListComboProto()
      this.isGlBankAccountLoading = false
    },
    generateSelectedAccounts() {
      const vm = this
      this.selectedItemAccounts = []
      this.receiptEntity.receiptItems.forEach(function (item) {
        vm.selectedItemAccounts.push(vm.glAccountListCombo.find((g) => g.accountNo === item.account))
      })
    },
    updateAfterGst(item) {
      item.gstAmount = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(item.totalAmount / this.innerValue.gstDivisor, 2)
      item.dealerCost = roundAwayFromZero(item.totalAmount - item.gstAmount, 2)
      this.$nextTick(() => {
        this.checkQtyAmount(this.receiptEntity)
      })
    },
    updateBeforeGst(item) {
      item.gstAmount = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(item.dealerCost * (this.innerValue.gstRate / 100), 2)
      item.totalAmount = roundAwayFromZero(item.dealerCost + item.gstAmount, 2)
      this.receiptEntity.totalAmount = this.totalAfterGst
      this.$nextTick(() => {
        this.checkQtyAmount(this.receiptEntity)
      })
    },
    checkRecQty(item) {
      if (item.received > item.owning) {
        item.received = item.owning
        // item.received = item.owning - item.totalReceivedQty + item.returnedQty
        // if (item.received > item.orderQty)
        //   item.received = item.orderQty
        this.$notification.openNotificationWithType('danger', title, 'Received Qty cannot be more than Owning Quantity.')
        // return false
      }

      if (item.received < item.returnedQty) {
        item.received = item.returnedQty
        this.$notification.openNotificationWithType(
          'danger',
          title,
          'This part has been returned ' + item.returnedQty + ' quantity. You cannot change the Received Quantity less than Returned Quantity.'
        )
        //return false
      }

      //calculate totalReceivedQty for all receipt order for the same PO item
      var totalRec = 0
      this.innerValue.orderReceipts.forEach(function (receipt) {
        let found = receipt.receiptItems.some((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)
        if (found) {
          let recQty = receipt.receiptItems.filter((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)[0].received
          totalRec = totalRec + recQty
        }
      })

      // update totalReceivedQty in all order receipt for the same PO item
      this.innerValue.orderReceipts.forEach(function (receipt) {
        let found = receipt.receiptItems.some((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)
        if (found) {
          let receiptItem = receipt.receiptItems.filter((i) => i.purchaseOrderItemId === item.purchaseOrderItemId)[0]
          receiptItem.totalReceivedQty = totalRec
        }
      })

      // update totalReceivedQty in AT_PurchaseOrderItem table
      var poItem = this.poItems.filter((i) => i.purchaseOrderItemId === item.purchaseOrderItemId && !i.deleted)[0]
      poItem.receivedQty = totalRec
      this.$nextTick(() => {
        this.checkQtyAmount(this.receiptEntity)
      })
    },
    checkGst(item) {
      if (item.gstAmount > 0) {
        var dUpp = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(item.totalAmount / this.innerValue.gstDivisor + 0.1, 2)
        var dLow = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(item.totalAmount / this.innerValue.gstDivisor - 0.1, 2)
        if (item.gstAmount < dLow || (item.gstAmount > dUpp && item.gstAmount != 0)) {
          item.gstAmount = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(item.totalAmount / this.innerValue.gstDivisor, 2)
          this.$nextTick(() => {
            this.checkQtyAmount(this.receiptEntity)
          })
          this.$notification.openNotificationWithType('danger', title, 'GST amount should be zero or between ' + dLow + ' and ' + dUpp)
          return false
        }
      } else {
        item.totalAmount = item.dealerCost
      }
    },
    updateOverrideTotal(receipt) {
      // receipt.overrideGst = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor, 2)
      receipt.overrideExGst = receipt.overrideIncGst - receipt.overrideGst
      var dUpp = roundAwayFromZero(this.overrideTotalAfterGst + 0.05, 2)
      var dLow = roundAwayFromZero(this.overrideTotalAfterGst - 0.05, 2)
      if (receipt.overrideIncGst < dLow || (receipt.overrideIncGst > dUpp && receipt.overrideIncGst != 0)) {
        receipt.overrideIncGst = roundAwayFromZero(this.totalAfterGst, 2)
        this.$notification.openNotificationWithType('danger', title, 'Total Invoice amount should be between ' + dLow + ' and ' + dUpp)
        return false
      }
      this.checkQtyAmount(receipt)
    },
    checkOverrideGst(receipt) {
      if (receipt.overrideGst > 0) {
        var dUpp = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor + 0.1, 2)
        var dLow = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor - 0.1, 2)
        if (receipt.overrideGst < dLow || (receipt.overrideGst > dUpp && receipt.overrideGst != 0)) {
          receipt.overrideGst = this.innerValue.gstDivisor == 0 ? 0 : roundAwayFromZero(receipt.overrideIncGst / this.innerValue.gstDivisor, 2)
          this.$notification.openNotificationWithType('danger', title, 'GST amount should be zero or between ' + dLow + ' and ' + dUpp)
          return false
        }
      }
    },
    async checkInvoiceNoExists(receipt) {
      if (receipt.invoiceNo) {
        let found = receipt.invoiceNo.toUpperCase().includes(InvoicePrefixShortFormTypes.RFC)
        if (found) {
          this.invoiceError = 'Cannot use ' + InvoicePrefixShortFormTypes.RFC + ' as part of invoice number.'
          this.isValidInvoice = false
        } else {
          let invoiceId = await PurchaseOrderService.checkInvoiceNoExists(receipt.invoiceNo, this.innerValue.vendorId, receipt.creditorInvoiceId)

          if (invoiceId === Guid.empty()) {
            this.isValidInvoice = true
            this.invoiceError = null
          } else {
            this.isValidInvoice = false
            this.invoiceError = 'Invoice Number already in used.'
          }
        }
      } else {
        this.isValidInvoice = false
        this.invoiceError = 'Invoice Number is required.'
      }
    },
    checkQtyAmount(receipt) {
      if (this.totalReceiptQty === 0 || (receipt.totalAmount === 0 && receipt.overrideIncGst === 0)) {
        this.isValidQtyAmt = false
      } else {
        this.isValidQtyAmt = true
      }
    },
    // addNewOrderReceipt(creditorInvoiceId) {
    //   const newReceipt = new PurchaseOrderReceiptModel(
    //     this.innerValue.companyId,
    //     this.innerValue.purchaseOrderId,
    //     creditorInvoiceId,
    //     this.innerValue.vendorId,
    //     this.innerValue.vendor.abn,
    //     this.innerValue.vendor.name,
    //     this.innerValue.quoteId,
    //     this.innerValue.vendorGlCode,
    //     this.innerValue.gstRate
    //   )
    //   newReceipt.invoiceDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()) //new Date().toISOString()
    //   this.innerValue.orderReceipts.push(newReceipt)

    //   let lastIndex = this.innerValue.orderReceipts.length - 1
    //   let receiptItems = this.innerValue.parts.concat(this.innerValue.miscs, this.innerValue.sublets, this.innerValue.otherPurchases)

    //   if (receiptItems.length > 0) {
    //     for (let i = 0; i < receiptItems.length; i++) {
    //       if (receiptItems[i].quantity > receiptItems[i].receivedQty) {
    //         const newItem = new PurchaseOrderReceiptItemModel(
    //           this.innerValue.companyId,
    //           creditorInvoiceId,
    //           this.innerValue.vendorId,
    //           receiptItems[i].purchaseOrderItemId
    //         )
    //         const date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
    //         newItem.quote2ItemId = receiptItems[i].quoteItemId !== null ? receiptItems[i].quoteItemId : Guid.empty()
    //         newItem.receivedDate = date.toISOString().split('.')[0] + 'Z'
    //         newItem.account = this.innerValue.vendorGlCode
    //         newItem.description = receiptItems[i].description
    //         newItem.itemNo = receiptItems[i].itemNo
    //         newItem.side = receiptItems[i].side
    //         newItem.orderQty = receiptItems[i].quantity
    //         newItem.owning = receiptItems[i].quantity - receiptItems[i].receivedQty
    //         newItem.totalReceivedQty = receiptItems[i].receivedQty
    //         newItem.partNo = receiptItems[i].partNo
    //         newItem.unitCost = receiptItems[i].qtePrice
    //         newItem.dealerCost =
    //           (receiptItems[i].quantity - (receiptItems[i].receivedQty - receiptItems[i].returnQty)) * (receiptItems[i].dealerCost / receiptItems[i].quantity)
    //         newItem.gstAmount = newItem.dealerCost * (this.innerValue.gstRate / 100)
    //         newItem.totalAmount = newItem.dealerCost + newItem.gstAmount
    //         this.innerValue.orderReceipts[lastIndex].receiptItems.push(newItem)
    //       }
    //     }
    //   }
    //   this.$eventHub.$emit(EventHubTypes.EntityReload)
    // },
    sort(name, order) {
      this.receiptEntity.receiptItems = _orderBy(this.activeItems, name, order)
    },
    backToListing() {
      if (this.receiptEntity.isNew && this.receiptEntity.receiptItems.length === 0) {
        this.innerValue.orderReceipts.splice(this.idx, 1)
        // this.$eventHub.$emit('deleteEmptyReceipt', this.idx)
      }

      this.$router.push({
        name: 'PurchaseOrderOrderReceipt',
        params: { purchaseOrderId: this.innerValue.purchaseOrderId }
      })
    },
    showAttachment() {
      this.isLoadAttachmentsActive = true
    },
    closeAttachmentsModal() {
      this.isLoadAttachmentsActive = false
    },
    async getAttachments() {
      const req = await PurchaseOrderService.getAttachments(this.$route.params.creditorInvoiceId)
      this.attachments = req.data.list
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL
      this.uploadedFiles = []
      this.uploadError = null
    },
    getQuoteAttachmentCategoryId(name) {
      const categories = this.quoteAttachmentCategories.filter((row) => row.name == name)
      if (categories.length == 1) {
        return categories[0].quoteAttachmentCategoryId
      }

      return 'xxx'
    },
    async save(formData) {
      this.$showSpinner()
      // upload data to the server
      this.currentStatus = STATUS_SAVING

      // console.log(this.quoteAttachmentCategories)

      const quoteAttachmentCategoryId = this.getQuoteAttachmentCategoryId('Supplier Invoices')
      UploadService.upload(formData, this.receiptEntity.creditorInvoiceId, this.receiptEntity.quoteId, quoteAttachmentCategoryId)
        .then((x) => {
          this.uploadedFiles = [].concat(x)
          this.currentStatus = STATUS_SUCCESS

          this.getAttachments()
          this.$refs.uploader.reset()
          this.$hideSpinner()
        })
        .catch((err) => {
          this.uploadError = err.response
          this.currentStatus = STATUS_FAILED
          this.$hideSpinner()
        })
    },
    filesChange(fieldName, fileList) {
      // handle file changes
      const formData = new FormData()

      if (!fileList.length) return

      const vm = this
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        // console.log(fileList[x].name.match(/\.[0-9a-z]+$/i))
        // console.log(fileList[x].name.match(/\.([^.]+)$/))
        // console.log(fileList[x].name.match(/\.([0-9a-z]+)(?:[?#]|$)/))
        const regex = fileList[x].name.match(/\.([0-9a-z]+)(?:[?#]|$)/)
        const ext = regex[1]
        switch (ext) {
          case 'jpg':
          case 'jpeg':
          case 'png':
          case 'pdf':
            formData.append(fieldName, fileList[x], fileList[x].name)
            // save it
            this.save(formData)
            break
          default:
            vm.$notification.openNotificationWithType('danger', 'Attachments', 'File type not allowed. Only JPG, JPEG, PNG & PDF are allowed')
        }
      })
    },
    async deleteAttachment(attachment) {
      this.currentAttachment = attachment
      this.isConfirmModalActive = true
    },
    async confirmDelete(decision) {
      this.isConfirmModalActive = false

      if (decision) {
        await UploadService.delete(this.currentAttachment.id)

        this.getAttachments()

        this.$refs.uploader.reset()
      }

      this.currentAttachment = null
    },
    async downloadAttachment(item) {
      const response = await UploadService.get(item.id)

      var blob = new Blob([response.data], {
        type: response.headers['content-type']
      })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = item.fileName
      link.style.display = 'none'
      document.body.appendChild(link)
      link.click()
      delete link.href

      return false
    },
    toogleReadOnly() {
      if (this.canChangeReadOnly) {
        this.setReadOnlyView(!this.readOnlyView)
      }
    },
    canChangeReadOnly() {
      return this.$userInfo.isSupportUser || this.$userInfo.isCustomerAdministrator
    }
  }
}
</script>

<style lang="scss" scoped>
.money-input {
  max-width: 6.5em;
}
.gst-input {
  max-width: 4.5em;
}
.quantity-input {
  max-width: 3.5em;
}
.partno-input {
  width: 8em;
}
.account-input {
  width: 18em;
}
</style>